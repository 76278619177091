import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from './plugins/axios';
import './assets/css/element-variables.scss'
import './assets/font/fonts.css'

import {Input,Select,Option,Radio,RadioGroup,Form,FormItem,Message,DatePicker,MessageBox} from 'element-ui';
Vue.component(Input.name, Input);
Vue.component(Radio.name, Radio);
Vue.component(Select.name, Select);
Vue.component(Option.name, Option);
Vue.component(RadioGroup.name, RadioGroup);
Vue.component(Form.name, Form);
Vue.component(FormItem.name, FormItem);
Vue.component(DatePicker.name, DatePicker);
Vue.prototype.$message = Message;
Vue.prototype.$confirm = MessageBox.confirm;
Vue.prototype.$alert = MessageBox.alert;

Vue.prototype.axios = axios;
Vue.prototype.url = require('../config/global').url
Vue.config.productionTip = false


new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
