<template>
    <div>
        <div class="nav">
            <div class="nav-left">
                <img class="logo" :src="logo" />
                <a  class="nav-name" href="https://www.uziliving.com" target="_blank">首页</a>
                <a tag="div"  class="nav-name" href="https://www.uziliving.com/student_apartment" target="_blank">学生公寓</a>
                <a tag="div"  class="nav-name" href="https://www.uziliving.com/apartment" target="_blank">优选公寓</a>
                <a tag="div" class="nav-name" href="https://www.uziliving.com/vip" target="_blank">VIP服务</a>
                <a tag="div" class="nav-name" href="https://www.uziliving.com/order" target="_blank">预定流程</a>
                <a tag="div" class="nav-name" href="https://www.uziliving.com/contactUs" target="_blank">联系我们</a>
                <a tag="div" class="nav-name login" href="https://www.uziliving.com/regist/login" target="_blank">登录/注册</a>
            </div>
        </div>
        <div class="mobile-nav">
            <div class="banner">
                <img class="mobile-logo" :src="logo" />
            </div>
            <div class="menu"  v-bind:style="{'backgroundImage':'url('+ menu_icon +')'}">
            </div>
        </div>
    </div>
</template>
<script>
import logo from '@assets/image/nav/logo.png'
import menu_icon from '@assets/image/nav/menu.png'

export default {
    props:['data'],
    data(){
        return {
            logo,menu_icon,
        }
    },
    mounted(){

    },
    methods:{

    }
}
</script>
<style lang="scss" scoped>
.mobile-nav{
    display: none;
}
.nav{
    width: 60rem;
    margin: 0 auto;
    color: #4d4d4d;
    display: flex;
    line-height: 4.8rem;
    font-size: 1.1rem;
    justify-content: space-between;
}
.nav-left{
    display: flex;
    align-items: center;
    .logo{
        width: auto;
        height:auto;
        margin-right: 2rem;
    }
}
.nav-name{
    margin-left: 0.8rem;
    padding: 0 1rem;
    cursor: pointer;
    display: block;
    position: relative;
    .down_line{
        width: 2.05rem;
        height: 0.25rem;
        background-color: #efa3bb;
        position: absolute;
        bottom: 0.3rem;
        left: 50%;
        right: 50%;
        margin-left: -1.1rem;
    }
    .right{
        margin-left: 0rem;
    }
}
.login{
    font-size: 0.9rem;
    margin-left: auto;
}
.nav-active{
    font-weight: 700;
    cursor: pointer;
}
@media only screen and (max-width: 1024px){
    /deep/ :focus{outline:0;}
    .nav{
        display: none;
    }
    .mobile-nav{
        width: 100%;
        position: fixed;
        top: 0;
        z-index: 999;
        background: #fff;
        height: 4.5rem;
        display: flex;
        padding: 0.6rem 0.4rem 0 0.8rem;
        justify-content: space-between;
        align-items: center;
        color: $font;
        font-weight: 700;
        font-size: 1.2rem;
        .mobile-logo{
            width: auto;
            height: auto;
        }
        .menu{
            width: 3.2rem;
            height: 3.2rem;
            background-repeat: no-repeat;
            background-position: center;
            background-size: 1.8rem 1.3rem;
        }
    }
}
</style>