import Vue from 'vue';
import axios from 'axios';
import Qs from 'qs'
// Vue.use(axios);  axios不支持use安装
// import { VIEW_LOADING } from '../store/types'
//拦截器
// 请求时

axios.interceptors.request.use(function (config) {
  // 请求之前，做点事
  config.headers={'Content-Type': 'application/x-www-form-urlencoded;'}  
  // store.dispatch(VIEW_LOADING,true)
  // config.data.token = 
  // store.commit('showLoading')
  if(config.data){
    config.data = Qs.stringify(config.data)
  }
  console.log(config)
  return config;
}, function (error) {
  // 错误请求，做点事
  // store.commit('hideLoading')
  alert('遇到意外错误，请联系顾问反馈错误！', '意外错误', {
    confirmButtonText: '确定',
        callback: ()=> {

        }
    });
  return Promise.reject(error);
});

// 响应式，数据返回时
axios.interceptors.response.use(function (response) {
  // console.log('loading消失')
  // store.dispatch(VIEW_LOADING,false)
  // store.commit('hideLoading')
  return response;
}, function (error) {
  console.log(11)
  // store.commit('hideLoading')
  alert('遇到意外错误，请联系顾问反馈错误！', '意外错误', {
    confirmButtonText: '确定',
        callback: ()=> {

        }
    });
  return Promise.reject(error);
});

Vue.prototype.$axios = axios;// 组件里面: this.$axios;
window.axios = axios; //组件，非组件里面都可以  axios()

export default axios;