<template>
  <div>
    <nav-component class="nav"></nav-component>
    <div class="margin-center main">
      <div class="index-img">
        <img class="index" :src="index" />
      </div>
      <div class="button-item">
        <div class="button"><div class="font" @click="goBills()">立即预定</div></div>
        <div class="button"><div class="font" @click="openBox()">更多咨询</div></div>
      </div>
      <div class="mobile-button">
        <img class="button-img" @click="goBills()" :src="button_order" />
        <img class="button-img" @click="openBox()" :src="button_more" />
      </div>
    </div>
    <div v-if="show" class="iframe">
      <div @click="closeBox()" class="closes">×</div>
      <iframe style="width:100%;height:100%;" src="https://tb.53kf.com/code/client/039e409f0a04edd204b7c558d4fdaac63/1" frameborder="0"></iframe>
    </div>
  </div>
</template>
<script>
import index from '@assets/image/index.png'
import NavComponent from '@components/NavComponent'
import button_more from '@assets/image/button-more.png'
import button_order from '@assets/image/button-order.png'
import close from '@assets/image/close.png'
export default {
    name: 'Index',
    components:{
        NavComponent
    },
  data(){
    return {
      index,button_more,button_order,
      close,
      show:false
    }
  },
  mounted(){

  },
  methods:{
    goBills(){
      window.open('/bills',"_blank")
    },
    openBox(){
      this.show = true
    },
    closeBox(){
      this.show = false
    }
  }
}
</script>
<style lang='scss' scoped>
.index{
  width: 750px;
  margin: 0 auto;
}
.main{
  position: relative;
}
.button-item{
  position: fixed;
  right: 14rem;
  top: 50%;
  cursor: pointer;
}
.button{
  width: 5rem;
  height: 5rem;
  border-radius: 50%;
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  background: #ef744c;
  border: 2px solid #3c200d;
}
.font{
  width: 2rem;
  margin: 0 auto;
  color: #fff;
}
.mobile-button{
  display: none;
}
.iframe{
  position: fixed;
  right: 0;
  bottom: 0;
  width: 50rem;
  height: 36rem;
}
.closes{
    position: absolute;
    right: 20px;
    top: 10px;
    color: #fff;
    cursor: pointer;
}
@media only screen and (max-width: 1200px){
  .nav{
    display: none;
  }
  .main{
    width: 100%;
  }
  .index{
    width: 100%;
  }
  .button-item{
    display: none;
  }
  .mobile-button{
    position: fixed;
    display: block;
    z-index: 999;
    right: 0rem;
    top: 60%;
    .button-img{
      display: block;
      margin-bottom: 2rem;
    }
  }
  .mobile-bg{
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 60;
    height: 100%;
    background: rgba($color: #000000, $alpha: 0.6);
  }
  .close{
    position: absolute;
    right: 0;
    width: 4rem;
    height: 4rem;
    top: -4rem;
    cursor: pointer;
  }
  .iframe{
    position: fixed;
    right: 0;
    bottom: 0;
    background: #fff;
    z-index: 100;
    width: 100%;
    height: 80%;
  }
}
</style>
